import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/usr/src/app/apps/platform/src/providers/auth-user-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/usr/src/app/apps/platform/src/providers/i18n-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LastPageProvider"] */ "/usr/src/app/apps/platform/src/providers/last-page-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/usr/src/app/apps/platform/src/providers/sidebar-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/usr/src/app/apps/platform/src/providers/trpc-provider.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/components/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/providers/design.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/packages/ui/src/styles/global.css");
