import { comboboxAnatomy } from '@ark-ui/anatomy';
import { defineSlotRecipe } from '@chakra-ui/react';
import { inputRecipe } from './input';

export const comboboxSlotRecipe = defineSlotRecipe({
  slots: comboboxAnatomy.keys(),
  className: 'combobox',
  base: {
    root: {
      width: '100%'
    },
    label: {
      fontWeight: 'medium',
      color: 'gray.700',
      marginBottom: '2'
    },
    control: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      mb: 1
    },
    input: {
      ...inputRecipe.base
    },
    trigger: {
      marginLeft: '2',
      padding: '2',
      borderRadius: 'md',
      backgroundColor: 'gray.100',
      _hover: {
        backgroundColor: 'gray.200'
      }
    },
    clearTrigger: {
      marginLeft: '2',
      padding: '2',
      borderRadius: 'md',
      color: 'gray.500',
      _hover: {
        color: 'gray.700'
      }
    },
    positioner: {
      zIndex: '1'
    },
    content: {
      backgroundColor: 'white',
      borderRadius: 'md',
      boxShadow: 'md',
      overflow: 'hidden'
    },
    itemGroup: {
      paddingY: '1'
    },
    itemGroupLabel: {
      fontWeight: 'bold',
      color: 'gray.600',
      fontSize: 'sm'
    },
    item: {
      cursor: 'pointer',
      _hover: {
        backgroundColor: 'gray.subtle',
        color: 'gray.700'
      },
      _selected: {
        backgroundColor: 'gray.subtle',
        color: 'gray.700'
      }
    },
    itemText: {
      flex: '1'
    },
    itemIndicator: {
      color: 'blue.500'
    }
  },
  variants: {
    size: {
      xs: {
        input: {
          ...inputRecipe.variants?.size?.xs
        },
        trigger: {
          padding: '1'
        },
        clearTrigger: {
          padding: '1'
        },
        item: {
          py: '1',
          px: '3.5'
        }
      },
      sm: {
        input: {
          ...inputRecipe.variants?.size?.sm
        },
        trigger: {
          padding: '2'
        },
        clearTrigger: {
          padding: '2'
        },
        item: {
          py: '1',
          px: '4'
        }
      },
      md: {
        input: {
          ...inputRecipe.variants?.size?.md
        },
        trigger: {
          padding: '3'
        },
        clearTrigger: {
          padding: '3'
        },
        item: {
          py: '1',
          px: '5'
        }
      },
      lg: {
        input: {
          ...inputRecipe.variants?.size?.lg
        },
        trigger: {
          padding: '4'
        },
        clearTrigger: {
          padding: '4'
        },
        item: {
          py: '1.5',
          px: '5'
        }
      }
    }
  },
  defaultVariants: {}
});
