'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getFetch, httpBatchLink, loggerLink } from '@trpc/client';
import { useState } from 'react';
import superjson from 'superjson';
import { trpc } from '@server/trpc';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5_000 } }
});

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/api/trpc/`;

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        loggerLink({
          enabled: () => true
        }),
        httpBatchLink({
          url,
          fetch: async (input, init?) => {
            const fetch = getFetch();
            return fetch(input, init);
          },
          transformer: superjson
        })
      ]
    })
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
