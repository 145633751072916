'use client';

import { PLATFORM_LANGUAGES } from '@company/common/types';
import { AuthUser } from '@typings/auth-user';
import React from 'react';

interface AuthUserContextProps {
  authUser: AuthUser;
  localeCode: 'de' | 'en';
}

const UserContext = React.createContext<AuthUserContextProps>({
  authUser: {
    id: '',
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    organization: {
      id: '',
      name: ''
    },
    status: 'ACTIVE',
    role: 'MEMBER',
    language: 'EN'
  },
  localeCode: 'en'
});
export const useAuthUser = () => React.useContext(UserContext);

export const AuthProvider = ({
  authUser,
  children
}: {
  authUser: AuthUser;
  children: React.ReactNode;
}) => {
  const value = React.useMemo(
    () => ({
      authUser,
      localeCode: PLATFORM_LANGUAGES[authUser.language].localeCode
    }),
    [authUser]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
